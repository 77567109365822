@import url(https://fonts.googleapis.com/css?family=Lato:900|Creepster);
@import url(https://fonts.googleapis.com/css?family=MedievalSharp);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

body {
  margin: 0px;
  background-color: black;
  overflow: hidden;
}

video {
  background-color: #000;
}

#customerly-container {
  z-index: 3000;
}
.clickable {
  pointer-events: all;
  cursor: pointer;
}

#cover,
.singlevideoInteraction {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}
.introbutton a {
  text-decoration: none;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
}

#buttonstart {
  margin: auto;
  font-family: 'Oswald';
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

#buttonstart a {
  background-image: url(https://media0.giphy.com/media/3bc8pP1rVdfgN1uoMV/giphy.gif);
  background-size: cover;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  font-size: 63px;
  line-height: 1;
}

.introbutton {
  position: absolute;
  font-family: 'Oswald';
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  min-width: 10%;
}

.introbutton a {
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    color: #fff;
    text-shadow: 0 0 10px #00fff2, 0 0 20px #00fff2, 0 0 30px #00fff2,
      0 0 40px #00fff2, 0 0 50px #00fff2, 0 0 60px #00fff2, 0 0 70px #00fff2,
      0 0 90px #00fff2;
  }

  to {
    color: rgb(255, 255, 255);
    text-shadow: 0 0 20px #00fff2, 0 0 30px #00fff2, 0 0 40px #00fff2,
      0 0 50px #00fff2, 0 0 60px #00fff2, 0 0 70px #00fff2, 0 0 80px #00fff2,
      0 1 90px #00fff2;
  }
}

#introcontentlayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background-size: cover;
}

.videofull {
  position: relative;
  width: 100%;
  height: 100%;
}

.fulldiv {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  min-height: 100%;
}

video {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.video-js,
.absvideo {
  width: 100%;
  height: 100%;
}

.abslayer {
  position: absolute;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#NavBar {
  background-color: #ffffff75;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-bottom: solid 1px #000000;
  flex-direction: row;
  display: flex;
}

.menuItem {
  padding: 20px;
  align-self: center;
  cursor: pointer !important;
  text-transform: uppercase;
}

#hamburger {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 5000;
  cursor: pointer !important;
}

.handlercontainer {
  cursor: pointer;
  z-index: 100000;
  pointer-events: all;
  position: absolute;
}

.handlercontainer .mainImg img {
  max-width: 100%;
  max-height: 100%;
}

.backgroundmusic {
  width: 0px;
  height: 0px;
}

.lastracontent {
  font-family: 'MedievalSharp';
  font-size: 4em;
  font-weight: bold;
  color: #171515;
}

.lastrainput {
  font-family: 'MedievalSharp';
  font-size: 4em;
  display: flex;
  flex-direction: column;
}
.lastracontent .dina {
  color: darkred;
}

.lastrainput input {
  pointer-events: all;
  height: 2em;
  font-size: 1em;
  min-width: 250px;
  background-color: darkgrey;
  padding: 10px;
  border-radius: 20px;
}

.buttonlastra {
  cursor: pointer;
  display: block;
  font-family: 'Creepster';
  font-size: 50px;
}

html,
body {
  height: 100%;
}
button {
  background: none;
  border: none;
  cursor: pointer;
}
button,
button span {
  position: relative;
}
button span {
  white-space: nowrap;
  display: block;
}
button::moz-focus-inner {
  padding: 0;
  border: 0;
}
body {
  background-image: linear-gradient(
      to bottom,
      rgba(22, 20, 44, 0.9) 0%,
      rgba(2, 1, 17, 0.9) 25%,
      rgba(2, 1, 17, 0.9) 45%,
      rgba(39, 42, 53, 0.95) 100%
    ),
    url('https://images.unsplash.com/photo-1514539079130-25950c84af65?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.wrap {
  position: relative;
  top: 10%;
}
.wrap h1,
.wrap em {
  font: 100 120px Creepster, Helvetica, sans-serif;
  color: #ffffff95;
}
.wrap em {
  font: 900 18px Lato, Helvetica, sans-serif;
  text-transform: uppercase;
  text-shadow: none;
}
.wrap .button-wrapper {
  margin-top: 80px;
}
.wrap .button-wrapper button span {
  padding: 30px 50px;
  background: rgba(255, 255, 255, 0.07);
  font: 900 18px Lato, Helvetica, sans-serif;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  border: rgba(255, 255, 255, 0.5) 3px solid;
}
.wrap .button-wrapper button:hover span {
  border-color: #fff;
  text-shadow: rgba(255, 255, 255, 0.5) 0 0 10px;
  color: #fff;
}

.radialbackground {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 50% 50%,
    rgba(231, 239, 240, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 50% 50%,
    rgba(231, 239, 240, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 50% 50%,
    rgba(231, 239, 240, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 50% 50%,
    rgba(231, 239, 240, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 50% 50%,
    rgba(231, 239, 240, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
